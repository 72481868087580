<template>
    <div class="how-it-work full-width" v-if="translates && translates[langUrl]">
        <div
            class="how-it-work__main-image">
            <!-- <v-carousel-item
                v-for="(picture, i) in pictures"
                :key="i"
                style="background: url(../assets/howItWorkork/head.jpg)"
            /> -->
            <div
                v-if="!isDesktop"
                class="how-it-work__main-image__action how-it-work__main-image__action_back"
                @click="back()">
                <img src="../assets/BackArrow.svg" class="how-it-work__main-image__action__icon" />
            </div>
            <div class="how-it-work__main-image__opacity"></div>
            <div class="how-it-work__main-image__container">
                <div class="how-it-work__main-image__container__text">
                    <div class="how-it-work__main-image__container__title">{{ translates[langUrl].pageTitle[lang] }}</div>
                </div>
            </div>
        </div>
        <div class="how-it-work__main-container__title" v-html="translates[langUrl].pageComment[lang]"></div>
        <div class="how-it-work__main-container">
            <div class="how-it-work__block">
                <div class="how-it-work__block__text-block">
                    <div class="how-it-work__block__text-block__text">
                            <div class="how-it-work__block__text-block__title">{{ translates[langUrl].block_1_title[lang] }}</div>
                        <div class="how-it-work__block__text-block__description">{{ translates[langUrl].block_1_comment[lang] }}</div>
                        <div v-if="!isDesktop" class="how-it-work__block__picture how-it-work__block__picture__talk"></div>
                        <div v-html="translates[langUrl].block_1_text[lang]"></div>
                        <div class="how-it-work__block__text-block__info">{{ translates[langUrl].block_1_info[lang] }}</div>
                    </div>
                </div>
                <div v-if="isDesktop" class="how-it-work__block__picture how-it-work__block__picture__talk"></div>
            </div>
            <div class="how-it-work__block">
                <div class="how-it-work__block__text-block">
                    <div class="how-it-work__block__text-block__text">
                            <div class="how-it-work__block__text-block__title">{{ translates[langUrl].block_2_title[lang] }}</div>
                        <div class="how-it-work__block__text-block__description">{{ translates[langUrl].block_2_comment[lang] }}</div>
                        <div v-if="!isDesktop" class="how-it-work__block__picture how-it-work__block__picture__plan"></div>
                        <div v-html="translates[langUrl].block_2_text[lang]"></div>
                    </div>
                </div>
                <div v-if="isDesktop" class="how-it-work__block__picture how-it-work__block__picture__plan"></div>
            </div>
            <div class="how-it-work__block">
                <div class="how-it-work__block__text-block">
                    <div class="how-it-work__block__text-block__text">
                            <div class="how-it-work__block__text-block__title">{{ translates[langUrl].block_3_title[lang] }}</div>
                        <div class="how-it-work__block__text-block__description">{{ translates[langUrl].block_3_comment[lang] }}</div>
                        <div v-if="!isDesktop" class="how-it-work__block__picture how-it-work__block__picture__brend"></div>
                        <div v-html="translates[langUrl].block_3_text[lang]"></div>
                    </div>
                </div>
                <div v-if="isDesktop" class="how-it-work__block__picture how-it-work__block__picture__brend"></div>
            </div>
            <div class="how-it-work__block">
                <div class="how-it-work__block__text-block">
                    <div class="how-it-work__block__text-block__text">
                            <div class="how-it-work__block__text-block__title">{{ translates[langUrl].block_4_title[lang] }}</div>
                        <div class="how-it-work__block__text-block__description">{{ translates[langUrl].block_4_comment[lang] }}</div>
                        <div v-if="!isDesktop" class="how-it-work__block__picture how-it-work__block__picture__plan"></div>
                        <div v-html="translates[langUrl].block_4_text[lang]"></div>
                    </div>
                </div>
                <div v-if="isDesktop" class="how-it-work__block__picture how-it-work__block__picture__plan"></div>
            </div>
        </div>
        <div class="how-it-work__pink-block">
            <div class="how-it-work__pink-block__icon"></div>
            <div class="how-it-work__pink-block__text">{{ translates[langUrl].pinkBlock_text[lang] }}</div>
        </div>
        <div class="how-it-work__offer">
            <div class="how-it-work__offer__text">
                <div class="how-it-work__offer__title">{{ translates[langUrl].offer_title[lang] }}</div>
                <div class="how-it-work__offer__comment">{{ translates[langUrl].offer_comment[lang] }}</div>
            </div>
            <BaseButton
                class="how-it-work__offer__button button-fill"
                @click="goTo('blogger-registration')"
            >{{ translates[langUrl].button_registration[lang] }}</BaseButton>
        </div>
        <div class="how-it-work__questions">
            <div class="how-it-work__questions__title">{{ translates[langUrl].block_questions_title[lang] }}</div>
            <v-expansion-panels class="how-it-work__questions__items">
                <v-expansion-panel
                v-for="(question,i) in pageTemplate.questions"
                :key="i"
                class="how-it-work__question"
                >
                    <v-expansion-panel-header class="how-it-work__question__header">
                        {{ question.question[lang] }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="how-it-work__question__content">
                        {{ question.answer[lang] }}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <div class="how-it-work__pink-block" style="margin-bottom: 0">
            <div class="how-it-work__pink-block__container">
                <div class="how-it-work__pink-block__text">{{ translates[langUrl].pinkBlock_2_text[lang] }}</div>
                <BaseButton
                    class="button-fill"
                    style="border: 1px solid #FFFFFF !important;"
                    @click="goTo('blogger-registration')"
                >{{ translates[langUrl].button_registration[lang] }}</BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { TRIP_STATUS, TRIP_TYPE } from '@/vars';

    import BaseButton from '../components/common/BaseButton.vue';
    import MobilePreview from '../components/common/trip/PreviewCard'

    import moment from '@/plugins/moment';
    moment.locale('ru');

    export default {
        name: 'HowItWork',
        metaInfo() {
            return {
                title: this.translates[this.langUrl]? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            BaseButton,
            Splide,
            SplideSlide,
            MobilePreview
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            tourSplideCounter: 1,
            pictures: [
                {
                    src: '../assets/brand/head/1.jpg'
                },
                {
                    src: `${ process.env.VUE_APP_CURRENT_URL }/assets/brand/head/2.jpg`
                },
                {
                    src: `${ process.env.VUE_APP_CURRENT_URL }/assets/brand/head/3.jpg`
                },
                {
                    src: `${ process.env.VUE_APP_CURRENT_URL }/assets/brand/head/4.jpg`
                }
            ],
            langUrl: 'HowItWork'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('pageTemplates', {
                pageTemplate: state => state.questions
            }),
        },
        created() {
            this.getTrips();
            this.isDesktop = !isMobile();
            this.tourSplideCounter = SplideSlideCounter(350, 1280);
            this.placesSplideCounter = SplideSlideCounter(260, 910, (0.3*innerWidth));
        },
        methods: {
            toggleDialogUserType(e) {
                this.$store.commit('setDialogUserType', !this.showDialogUserType);
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
            async back() {
                await this.$router.back();
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { filter: {
                    ...this.filter,
                    type: TRIP_TYPE.TOUR_LEADER,
                    status: TRIP_STATUS.ACTIVE
                }, sorting: this.sorting });
            },
            async favoriteAdd({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteAdd', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                }
            },
            async favoriteDelete({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteDelete', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                }   
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
        },
    };
</script>

<style lang="scss">
.how-it-work {
    width: 100%;
    &__main-image {
        position: relative;
        width: 100%;
        height: 440px;
        background: url(../assets/howItWork/head.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
        }
        // &__opacity {
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     background: #543919;
        //     opacity: 0.5;
        // }
        &__container {
        position: absolute;
        width: 100%;
        height: 350px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
        color: #FFFFFF;
            @media all and (max-width: 768px) {
                height: 254px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                width: 1280px;
                margin: 120px auto;
                @media all and (max-width: 768px) {
                    width: 100%;
                    padding: 20px;
                    margin: 0 auto;
                }
            }
            &__title {
                font-weight: bold;
                font-size: 56px;
                // margin-top: 245px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    margin-top: 115px;
                    margin-bottom: 20px;
                }
            }
            &__comment {
                font-size: 24px;
                margin-top: 8px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-size: 20px;
                }
            }
            &__button {
                width: 180px;
                @media all and (max-width: 768px) {
                    width: 240px;
                }
            }
        }
        &__action {
            position: absolute;
            display: flex;
            height: 32px;
            width: 32px;
            top: 40px;
            background: #FFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                cursor: pointer;
            }
            &_back {
                left: 20px;
            }
            &__icon {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }
    }
    &__pink-block {
        width: 100%;
        height: 300px;
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;
        @media all and (max-width: 768px) {
            height: 225px;
            margin-bottom: 60px;
        }
        &__container {
            max-width: 1400px;
            width: calc(100% - 40px);
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media all and (max-width: 768px) {
                width: 100%;
                flex-direction: column;
            }
            .how-it-work__pink-block__text {
                font-size: 46px;
                line-height: 56px;
                @media all and (max-width: 768px) {
                    font-size: 24px;
                    line-height: 130%;
                    margin-bottom: 20px;
                }
            }
        }
        &__icon {
            width: 55px;
            height: 55px;
            background: url(../assets/brand/icon.png);
            @media all and (max-width: 768px) {
                margin-bottom: 20px;
            }
        }
        &__text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 52px;
            line-height: 63px;
            color: #FFFFFF;
            @media all and (max-width: 768px) {
                font-size: 24px;
                line-height: 130%;
                text-align: center;
            }
        }
    }
    &__main-container {
        max-width: 1280px;
        margin: 0 auto;
        &__title {
            max-width: 1280px;
            margin: 80px auto;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            @media all and (max-width: 768px) {
                margin: 60px 20px;
                font-size: 28px;
                line-height: 34px;
            }
        }
    }
    &__block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 80px;
        &:nth-of-type(2n) {
            flex-direction: row-reverse;
        }
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 50px;
            &:nth-of-type(2n) {
                flex-direction: column;
            }
        }
        &__text-block {
            display: flex;
            flex-direction: row;
            width: 500px;
            @media all and (max-width: 768px) {
                width: 100%;
                padding: 0 20px;
                margin-bottom: 12px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                font-family: 'Inter';
                font-style: normal;
            }
            &__title {
                font-weight: 700;
                font-size: 28px;
                line-height: 140%;
                color: #273155;
                margin-bottom: 32px;
                @media all and (max-width: 768px) {
                    font-size: 28px;
                    line-height: 130%;
                }
            }
            &__description {
                font-weight: 600;
                font-size: 20px;
                line-height: 140%;
                color: #3A4256;
                margin-bottom: 32px;
                @media all and (max-width: 768px) {
                    font-size: 18px;
                }
            }
            ul > li {
                font-weight: 400;
                font-size: 18px;
                line-height: 160%;
                color: #3A4256;
                margin-bottom: 17px;
                span {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 180%;
                    text-decoration-line: underline;
                    color: #3107D8;
                    cursor: pointer;
                }
                ul > li {
                    margin-top: 12px;
                    font-size: 16px;
                    line-height: 150%;
                    span {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 180%;
                        text-decoration-line: underline;
                        color: #3107D8;
                        cursor: pointer;
                    }
                }
            }
            // &__point {
                
            //     &__second {
                    
            //     }
            // }
            // &__link {
            //     font-weight: 400;
            //     font-size: 18px;
            //     line-height: 180%;
            //     text-decoration-line: underline;
            //     color: #3107D8;
            // }
            &__info {
                padding: 24px;
                background: #E9F0FF;
                border-radius: 12px;
                margin-top: 32px;
                font-style: italic;
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
            }
            &__button {
                width: 239px;
                height: 52px;
                margin: 0 auto 0 0;
                @media all and (max-width: 768px) {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
        }
        &__picture {
            width: 600px;
            height: 430px;
            background-size: cover;
            border-radius: 10px;
            @media all and (max-width: 768px) {
                width: 100%;
                height: 220px;
                margin: 0 0 40px;
            }
            &__talk {
                background-image: url(../assets/brand/Talk.jpg);
            }
            &__plan {
                background-image: url(../assets/brand/Plan.jpg);
            }
            &__brend {
                background-image: url(../assets/brand/Brand.jpg);
            }
        }
    }
    &__block-name {
        width: 1280px;
        margin: 80px auto 40px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        color: #273155;

    }
    &__offer {
        display: flex;
        flex-direction: row;
        padding: 32px;
        background: #F6F7F9;
        border-radius: 12px;
        max-width: 800px;
        margin: 0 auto;
        justify-content: space-between;
        @media all and (max-width:768px) {
            flex-direction: column;
            margin-bottom: 20px;
            margin: 0 20px;
        }
        &__title {
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            margin-bottom: 8px;
            @media all and (max-width:768px) {
                font-size: 16px;
                margin-bottom: 14px;
            }
        }
        &__text {
            font-family: 'Inter';
            font-style: normal;
            margin-right: 50px;
            color: #273155;
        }
        &__comment {
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            @media all and (max-width:768px) {
                font-size: 14px;
                margin-bottom: 17px;
            }
        }
        &__button {
            @media all and (max-width:768px) {
                width: 100%;
            }
        }
    }
    &__questions {
        max-width: 1280px;
        margin: 80px auto;
        @media all and (max-width:768px) {
            padding: 0 20px;
        }
        &__title {
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin-bottom: 60px;
        }
        &__items {
            border: none;
            border-radius: 12px;
        }
    }
    &__question {
        padding: 32px 34px;
        margin-bottom: 12px;
        border-radius: 12px;
        font-family: 'Inter';
        font-style: normal;
        &.v-expansion-panel--active {
            border: 1px #D80765 solid;
            border-radius: 12px !important;
            margin: 0;
        }
        &__header {
            padding: 0;
            min-height: auto;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: #273155;
            border-radius: 12px;
            &.v-expansion-panel-header--active {
                min-height: auto;
                @media all and (max-width:768px) {
                    margin-bottom: 16px;
                }
                .v-expansion-panel-header__icon {
                    ::before {
                        color: #D80765;
                    }
                }
            }
            
        }
        &__content {
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #616D89;
            .v-expansion-panel-content__wrap {  
                padding: 0;
                max-width: 1000px;
            }
        }
    }
}

</style>